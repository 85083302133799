<template>
    <div>
        <div class="row mt-5">
            <div class="col-12" v-if="Object.entries(response).length != 0 && response.success != true">
                <div class="alert alert-danger" role="alert">
                    <strong>Error</strong> {{response.message}}
                </div>
            </div>
            <div class="col-12">
                <label htmlFor="slug" className="form-label">
                    Slug
                </label>
                <div className="input-group">
                    <input type='text' v-model="slug" class="form-control" id="slug">
                    <button className="btn btn-primary" type="button" @click="checkSlug">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import axios from 'axios'

export default {
    data() {
        return {
            slug:'',
            response: {}
        }
    },
    watch: {
        response(v)
        {
            if(v.success == true) window.location.href = `${process.env.VUE_APP_BASE_URL}/${v.data.slug}`
        }
    },
    methods: {
        async checkSlug() {
            try {

                if (this.slug == '') throw new Error('slug was required')

                let res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/business/check`, {
                    params: {
                        slug: this.slug,
                    },
                })

                if (res.data.is_app == 0) throw new Error('You\'re not allowed to use this app')

                this.response = {
                    data: res.data,
                    success: true,
                    message: 'Success'
                }

            } catch (e) {
                this.response = {
                    data: null,
                    success: false,
                    message: 'Something went wrong'
                }
            }
        }
    },
}
</script>