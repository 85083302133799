<template>
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <MainVue />
      </div>
    </div>
  </div>
</template>

<script>

import MainVue from './components/Main.vue'


export default {
  name: 'App',
  components: {
    MainVue
  }
}
</script>

